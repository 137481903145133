@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Inter" !important;
  background: #f9f9f9 !important;
}

h2 {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 46px !important;
  color: #000000 !important;
}

h3,
.h3-text {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: #000000 !important;
}

h4 {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 36px !important;
  color: #000000 !important;
}

h5 {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

h6 {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

p {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.dashboard-title {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.text-link {
  color: #01aee8 !important;
  text-decoration: underline !important;
}
th {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: #000000 !important;
  padding: 5px !important;
}

td {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: #000000 !important;
  padding: 5px !important;
}

.MuiButton-label {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.past-days {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 24px !important;
  color: #7d7d7d !important;
}

.bg-green {
  background: #134989 !important;
}

.pos-back {
  position: absolute;
  left: 44%;
  top: 30px;
}

.pos-signin-up {
  position: absolute;
  right: 30px;
  top: 30px;
}

.p-6-5-rem {
  padding: 4rem 6.5rem !important;
}

.w-65 {
  width: 65%;
}

.w-450px {
  width: 450px;
}

label {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 17px !important;
  color: #000000;
}

input.form-control {
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
  height: 36px !important;
  font-size: 13px !important;
}

textarea.form-control {
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}

.cus-alert,
.error {
  color: #db0014 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  padding: 5px 0 0 0;
}

.success-alert {
  color: #01b46f;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 24px !important;
}

a {
  font-size: 13px !important;
  text-decoration: none !important;
}

.mt-30px {
  margin-top: 30px;
}

.form-control:focus,
.form-select:focus {
  color: #000000 !important;
  background-color: #fff;
  border: 1px solid #86b7fe !important;
  outline: 0;
  box-shadow: none !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px 0 0 8px !important;
  border-right: 0 !important;
  padding: 0 !important;
  background: #ffffff !important;
}

.fs-27px {
  font-size: 27px !important;
}

span.side-menu-nav {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 24px !important;
}

.menu-anchor:hover {
  background-color: transparent !important;
  color: #fff !important;
  text-decoration: underline !important;
}

.card {
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
  padding: 0 !important;
}

.cus-out-count {
  color: #be3531 !important;
  font-weight: 600 !important;
  border-radius: 100px;
  border: 1px solid #be3531 !important;
  width: 100px;
  text-align: center;
  padding: 4px;
  margin-right: 10px;
}

.cus-in-count {
  color: #01b46f !important;
  font-weight: 600 !important;
  border-radius: 100px;
  border: 1px solid #01b46f !important;
  width: 100px;
  text-align: center;
  padding: 4px;
}

select.form-select {
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  height: 36px !important;
  color: #000000 !important;
}

.css-1jqq78o-placeholder {
  font-size: 13px !important;
  color: #000000 !important;
  line-height: 14px !important;
}
.branch-table {
  width: 1300px !important;
}

.schedule-table {
  width: 1550px !important;
}
.usermanagement-table {
  width: 1300px !important;
}

.daily-task-table {
  width: 2000px !important;
}

.search .input-group-text {
  border: 1px solid #f0f0f0;
  border-right: 0;
  border-radius: 8px 0 0 8px !important;
}

.search .form-control {
  border-left: 0 !important;
  border-radius: 0 8px 8px 0 !important;
}

.page-link {
  color: #000000 !important;
}

/* Reset */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

/* Alert Color */
.text-red {
  color: red !important;
}

/* flex horizontal space between and vertical align center */
.flex-hsb-vc {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

/* All page Right side background color */
.background-body {
  background: #f4f4f4;
}

/* Sign up button on Signin page */
.align-nav {
  position: absolute;
  margin-bottom: 52.5rem;
  margin-left: 55.5rem;
}

/* both signin & Signup */
.text-white {
  color: white;
}

/* both signin & signup */
.text-project {
  color: #134989 !important;
}

/* All Page Left side background */
.left {
  background: #01b46f;
}

/* Text green */
.text-green {
  color: #01b46f;
}

/* Full width to all component */
.full-width {
  width: 100%;
}

/* Signin position */
.login-relative {
  position: relative;
}

/* login-button */
.login-button {
  background: #134989 !important;
  border-color: #134989 !important;
  width: auto !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  height: 36px !important;
  line-height: 20px !important;
}
.filter-button {
  background: #f9f9f9 !important;
  border-color: #134989 !important;
  color: #134989 !important;
  width: 90px !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  height: 36px !important;
  line-height: 20px !important;
}

/* Signup */
.login-absolute {
  position: absolute;
  top: 0;
  right: 0;
}

/* Signin & Signup page */
.sign-position {
  padding: 6.5rem !important;
}

/* Signin */
.img {
  height: 12.375rem;
  width: 20.625rem;
  margin-left: 5rem;
  margin-bottom: 1.875rem;
}

/* Signin */
.alignment {
  padding: 10rem;
  row-gap: 2rem;
}

/* Signup */
.terms {
  text-decoration: none;
}

/* Signup back button */
.back {
  background: #f9f9f9;
  border: 1px solid #000000;
  border-radius: 8px;
  color: #000000;
  width: 155px;
  height: 45px;
  margin-top: 2rem;
}

.card-style {
  width: 252px !important;
  height: 171px !important;
}

/* Signup next button */
.next {
  background: #01b46f;
  border: 1px solid #01b46f;
  border-radius: 8px;
  width: 155px;
  height: 45px;
  position: absolute;
  bottom: 19.1rem;
  right: 10.7rem;
}

/* Signup start my trial  */
.trial {
  background: #01b46f;
  border: 1px solid #01b46f;
  border-radius: 8px;
  width: 155px;
  height: 45px;
  position: absolute;
  bottom: 11.1rem;
  left: 78rem;
}

/* signin & Signup input field */
.signin {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  height: 40px !important;
  width: 428px !important;
}

/* horizontal line step 1 to 2 */
.style1 {
  border-top: 0.125rem solid #7d7d7d;
  width: 30.5rem;
  top: 16rem;
  left: 47.875rem;
}

/* green line Step 2 */
.style3 {
  border: 0.125rem solid #01b46f;
  width: 15.25rem;
  top: 16rem;
  left: 63.125rem;
  margin-top: -1.188rem;
}

/* green line step 1 */
.style2 {
  border: 0.125rem solid #01b46f;
  width: 15.25rem;
  top: 19.563rem;
  left: 47.875rem;
  margin-top: -1.188rem;
}

/* step 1 & space */
.space {
  margin-left: 15rem;
}

/* top nav */
.topnav {
  padding: 0 2.5rem !important;
  border-bottom: 1px solid #e9e9e9 !important;
  position: fixed;
  width: calc(100% - 260px) !important;
  z-index: 1;
  background: #f9f9f9;
  height: 55px;
  right: 0;
}

.icon-style {
  background: transparent !important;
  border-color: transparent !important;
}

/* side bar */
.sidebar-container {
  background: #134989;
  padding: 0;
  transition: width 0.25s ease;
  -webkit-transition: width 0.25s ease;
  -moz-transition: width 0.25s ease;
  -ms-transition: width 0.25s ease;
  width: 260px;
  height: 100vh;
  position: fixed;
  overflow-y: auto;
}

.sidebar {
  width: 100% !important;
  height: 100vh;
  min-width: 100% !important;
  border-right: none !important;
}

.sidebar-inner {
  background-color: #01b46f !important;
}

.side-nav {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0;
  padding-bottom: 3rem;
}

.side-navhead {
  padding: 0.6rem 2rem 0.6rem;
  width: 100%;
}

.side-navhead h1 {
  margin-bottom: 0 !important;
  font-size: 1.6875rem !important;
  font-weight: 700;
  line-height: 2rem;
}

.side-nav-menu {
  gap: 0.75rem;
}

.page-body-wrapper {
  display: flex;
  transition: width 0.25s ease, margin 0.25s ease;
}

.main-panel {
  flex-direction: column;
  transition: width 0.25s ease, margin 0.25s ease;
  width: calc(100% - 260px);
  position: absolute;
  right: 0;
}

a.ps-menu-button:hover {
  background: transparent !important;
  color: #fff;
  text-decoration: underline;
}

.content-wrapper {
  flex-grow: 1;
  padding: 2.25rem;
  width: 100%;
  margin-top: 55px;
}

.cus-w19-per {
  width: 19.6666% !important;
}

.p-2 a {
  padding: 5px;
  text-decoration: none;
}

.new-visit {
  width: 135px;
  height: 50px;
  background: #01b46f;
  border: 1px solid #01b46f;
  border-radius: 10px;
}

.visit-btn {
  position: absolute;
  padding-left: 70rem;
  padding-top: 1rem;
}

.btn-position {
  position: absolute;
  margin-left: 70rem;
  margin-top: -5rem;
}

.employee-box {
  position: absolute !important;
  width: 105px !important;
  height: 32px !important;
  border: 1px solid #01b46f !important;
  border-radius: 100px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 2px !important;
  background: #f0f0f0 !important;
  margin-top: 24px;
}

.activity-color {
  color: #01b46f !important;
  box-sizing: 12rem !important;
  background-position-x: center !important;
}

.out-count,
.in-count {
  margin-bottom: 0;
  width: 8.25rem;
  height: 2.5rem;
  border-radius: 2.9375rem;
  box-sizing: border-box;
  background-color: #f0f0f0;
}

.out-count span,
.in-count span {
  width: 100%;
  text-align: center;
}

.out-count {
  border: 1px solid #01b46f;
  color: #01b46f;
}

.in-count {
  border: 1px solid #be3531;
  color: #be3531;
  margin-right: 0.625rem;
}

.search-icon {
  background-color: #ffffff !important;
}

.search-size {
  position: absolute;
  width: 453px !important;
  height: 50px !important;
  left: 294px !important;
  top: 148px !important;
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 10px !important;
}

.search-position {
  position: absolute;
  margin-left: -25rem;
  margin-top: -7.5rem;
}

.employee-btn {
  position: absolute;
  margin-top: 2rem;
  margin-left: 35.5rem;
}

.text-decoration {
  text-decoration: none;
}

.activity-select {
  background: #ebebeb;
  border-radius: 100px;
}

.icon-style.dropdown-toggle:after {
  display: none !important;
}

.box-size {
  width: 325px !important;
  height: 231px !important;
  border-radius: 10px !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15) !important;
}
.box-sized {
  /* width: 325px !important;
  height: 231px !important; */
  border-radius: 10px !important;
  background: #ffffff !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15) !important;
}

.align-right {
  margin-left: auto;
}

.font-weight {
  font-weight: bolder;
}

.dropdown-size {
  height: fit-content !important;
}

.employee-page {
  padding: 1.875rem 3rem !important;
}

/* Home Page */
.card-row {
  padding: 1.875rem 2.9375rem !important;
}

.home-activity {
  padding: 0rem 0rem;
}

/* Home page more button */
.more-button {
  width: 135px;
  height: 40px;
  background: #01b46f !important;
  border: 1px solid #01b46f !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}

.w-100px {
  width: 100px;
}

/* Search Box */
.search {
  height: 36px !important;
}
.input-group.search svg {
  width: 16px !important;
  height: 16px !important;
}

/* Add Employee Create Button */
.create-button {
  height: 36px !important;
  width: 110px !important;
  background: #134989 !important;
  border: 1px solid #134989 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.black-button {
  height: 40px !important;
  width: 124px !important;
  background: #000000 !important;
  border: 1px solid #000000 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}

.locate-map-btn {
  background: #134989 !important;
  border: 1px solid #134989 !important;
  border-radius: 5px !important;
  font-size: 13px !important;
  color: #ffffff !important;
}

/* Add Employee Cancel Button */
.cancel-button {
  width: 110px !important;
  height: 36px !important;
  border: 1px solid #134989 !important;
  color: #134989 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}

/* Add Employee Dropdown style */
.employee-dropdown {
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  width: 382px !important;
  height: 40px !important;
}

/* Add Employee Phone Number field  */
.employee-input {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  width: 382px;
  height: 40px;
}

/* Home Page Activity Card */
.card-img {
  flex: 0 0 3.75rem;
}

.card-desc {
  flex: 0 0 calc(100% - 11.25rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.25rem;
}

.card-name {
  font-size: 13px;
  font-weight: 600;
}

.card-description {
  font-size: 13px;
  font-weight: 400;
}

.card-date {
  font-size: 12px;
  font-weight: 400;
}

.card-emp-btn {
  width: 6.5625rem;
  height: 2rem;
  border: 1px solid #01b46f !important;
  border-radius: 6.25rem !important;
  color: #01b46f !important;
  line-height: 1rem !important;
  font-size: 13px !important;
}

.card-emp-btn:hover {
  color: #ffffff !important;
  background-color: #01b46f !important;
}

.forgot-text {
  font-size: 32px;
  font-weight: 700;
}

.location-tab-head {
  font-size: 13px;
  font-weight: 600;
}

/* Top Nav Logout Button */
.logout-button {
  width: 100% !important;
  height: 40px !important;
  background: #134989 !important;
  border: 1px solid #134989 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}

/* Modal QR */
.delete-modal .modal-dialog,
.qr-modal .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  z-index: 10040;
}

.qr-modal img {
  height: 14.75rem;
}

.delete-modal .modal-body,
.qr-modal .modal-body {
  position: relative;
  padding: 3.1875rem;
}

.delete-modal .modal-body {
  padding: 2rem;
}

.delete-modal .qr-modal-close,
.qr-modal .qr-modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.qr-modal .qr-modal-footer,
.qr-modal .qr-modal-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-bg {
  background: #f9f9f9 !important;
}

.qr-modal .qr-modal-footer .login-button {
  height: 3.1875rem;
  width: 9.8125rem;
}

.qr-modal .qr-modal-img {
  margin-bottom: 1.5rem;
}

/* Location Table Delete */
.delete-cancel {
  height: 36px !important;
  width: 5.5625rem !important;
  background: #ffffff;
  border: 1px solid #000000 !important;
  color: #000000 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}

.delete-button {
  background: #134989 !important;
  border: 1px solid #134989 !important;
  color: #ffffff !important;
}

.delete-footer {
  gap: 1rem;
}

.modal-content {
  border: none !important;
}

.delete-cancel:hover {
  background-color: transparent !important;
}

.delete-button:hover {
  background-color: #01b46f !important;
}

.delete-modal {
  width: 26.5rem;
  height: 13.5rem;
}

/* Activity Table */
.activity-table {
  max-height: calc(100vh - 20rem);
  overflow-y: auto;
}

/* Forgot password Send Btn */
.send-btn {
  width: 168px !important;
  height: 40px !important;
  background: #134989 !important;
  border: 1px solid #134989 !important;
  border-radius: 8px !important;
  line-height: 20px !important;
  font-size: 13px !important;
}

.align-navs {
  position: absolute;
  margin-right: 65rem !important;
  margin-bottom: 53rem;
}

.ml-10px {
  margin-left: 10px !important;
}

.ps-sidebar-container {
  background: transparent !important;
}

.ps-sidebar-root {
  border-right: none !important;
}

.active > .page-link {
  background-color: #01b46f !important;
  border-color: #01b46f !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
}

.btn-text {
  padding: 13px 0 !important;
  font-size: 13px !important;
  border-top: 1px solid #f0f0f0 !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
  margin-top: 7px;
}

.update-pass {
  border-bottom: 1px solid #f0f0f0;
}

.update-pass-btn {
  width: 100% !important;
  height: 40px !important;
  background: #01aee8 !important;
  border: 1px solid #01aee8 !important;
  border-radius: 8px !important;
  color: #ffffff !important;
  font-size: 13px !important;
  line-height: 1;
}

.mob-menu {
  border: none;
  background: no-repeat;
  padding: 0 10px 0 0;
}

.table-profile {
  width: 40px;
  height: 40px;
}

th {
  border-bottom: 3px solid #f0f0f0 !important;
}
td {
  vertical-align: middle;
}

#table-page {
  border-bottom: 3px solid #f0f0f0 !important;
}

.last-data-cell {
  border-bottom: 3px solid #f0f0f0 !important;
}

tr {
  border-color: #f0f0f0;
}

table a {
  color: #01b46f !important;
  text-decoration: none !important;
}

.offc-img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.offc-title {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #000000 !important;
}

.offcanvas-header {
  padding: 25px !important;
  background: #f9f9f9;
}

.offcanvas-body {
  padding: 0 25px 25px 25px !important;
  background: #f9f9f9;
}

.text-green {
  color: #01b46f !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fs-19px {
  font-size: 17px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-27 {
  font-size: 27px !important;
}

.lh-27px {
  line-height: 27px !important;
}

.activity-status {
  width: 150px !important;
  margin-right: 20px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #f0f0f0 !important;
  border-radius: 8px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  background: #ffffff !important;
  height: 40px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 24px !important;
  color: #000000 !important;
  width: 100% !important;
}

.css-13cymwt-control {
  border-color: #f0f0f0 !important;
  border-radius: 8px !important;
  min-height: 36px !important;
}

.css-1dimb5e-singleValue {
  font-size: 13px !important;
  line-height: 16px !important;
}

.icon-style img {
  width: 30px;
  height: 30px;
}

.mt-28px {
  margin-top: 28px !important;
}

.mr-16px {
  margin-right: 16px;
}

.mr-6px {
  margin-right: 6px;
}

.h-300px {
  height: 300px;
}

.fs-10px {
  font-size: 10px !important;
}

.default.offcanvas.offcanvas-end {
  width: 400px !important;
}

.profile-ep {
  font-size: 12px !important;
  color: #7d7d7d !important;
}

.profile-edit-icon {
  padding: 0 !important;
  border: 0 !important;
}

.profile-edit-icon {
  background: transparent !important;
  position: absolute;
  right: 17px;
  top: 8px;
}

.barchart {
  height: 230px !important;
}

@supports (overflow-y: overlay) {
  .activity-table {
    overflow-y: overlay;
  }
}

.side-nav-link {
  display: flex;
  align-items: center;
  height: 2.7rem;
  padding: 0 1.25rem;
  text-decoration: none;
}

.side-nav-link.disabled {
  pointer-events: none;
}

.side-nav-link span {
  line-height: 1rem !important;
}

.side-nav-link.active span {
  border-bottom: 1px solid white;
}

.profile-img {
  display: flex;
  align-items: center;
  position: relative;
}

.profile-img-right {
  padding: 2px 4px;
  border-radius: 6.25rem;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  line-height: 1.25rem;
  position: relative;
  left: -0.75rem;
}

.profile-img-text {
  font-size: 0.625rem;
  line-height: 0.75rem;
}

.pagination {
  --bs-pagination-border-color: #f0f0f0 !important;
  --bs-pagination-hover-border-color: #f0f0f0 !important;
  --bs-pagination-disabled-border-color: #f0f0f0 !important;
}

.bg-grey {
  background: #f9f9f9;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.info-card {
  margin-top: 70px !important;
}

.toaster {
  width: 100% !important;
  border-radius: 0 !important;
  z-index: 9;
  box-shadow: none !important;
  border: none !important;
}

.toast-header {
  border-radius: 0 !important;
  background-color: #d6ffef !important;
  border: none !important;
}

.eye-field {
  position: relative;
}

.eye {
  position: absolute;
  top: 1px;
  right: 0;
}

.doughnut {
  height: 200px !important;
  width: 200px !important;
}

.graph-card {
  width: 534px !important;
  height: 386px !important;
}

.graph-heading {
  width: 144px !important;
  height: 24px !important;
}

.task-offcanvas {
  width: 750px !important;
}

.text-area {
  height: 90px !important;
}

.text-field {
  height: 40px !important;
}

.dropdown-field {
  height: 36px !important;
}

.offcanvas.offcanvas-end {
  width: 800px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.Mui-selected {
  color: #fff !important;
  background-color: #134989 !important;
  border-radius: 100px !important;
}
.css-195y93z-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  color: #fff !important;
  background-color: #134989 !important;
  border-radius: 100px !important;
}

.attachment-btn span[role="button"] {
  color: #000000 !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  border: 1px solid #f0f0f0 !important;
  text-transform: none !important;
  height: 36px !important;
  box-shadow: none !important;
}

.attachment-btn svg {
  margin-right: 15px;
}

.img-card .img-preview .img-thumb-perview {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}
.side-img-preview {
  width: 120px;
  object-fit: cover;
  outline: 6px solid #f0f0f0 !important;
}

.select-side-img-preview {
  width: 120px;
  object-fit: cover;
  outline: 6px solid #01aee8 !important;
}

.img-card .img-preview .react-fancybox .thumbnail img {
  width: 130px !important;
  height: 130px !important;
  object-fit: cover !important;
  border-radius: 8px !important;
  border: 1px solid #f0f0f0 !important;
}

.img-preview .react-fancybox .thumbnail img {
  width: 100% !important;
  object-fit: contain !important;
  height: 100% !important;
}

.sidebar-logo {
  padding-left: 37px;
  padding-right: 37px;
  padding-bottom: 20px;
  padding-top: 37px;
}

.sidebar-logo svg {
  width: 130px;
}

.attachment-btn svg {
  margin-right: 15px;
}

.img-preview {
  width: 100%;
  border-radius: 8px;
}

.img-preview .img-thumb-perview {
  width: 100%;
  object-fit: contain;
  height: 100%;
}

.img-card span {
  position: absolute;
  top: 13px;
}

.img-card .download {
  right: 37px;
}

.img-card .delete {
  right: 0;
  cursor: pointer;
}

.img-card .certificate-mark {
  position: relative;
}
.certificate-mark svg {
  position: absolute !important;
  top: -33px !important;
  left: 78px !important;
}
.img-card .certificate-mark svg {
  width: 35px !important;
  height: 35px !important;
}

.delete svg {
  width: 30px;
  height: 30px;
}

.download svg {
  width: 30px;
  height: 30px;
}

.off-canvas-schedule {
  width: 600px !important;
}
.img-card {
  position: relative;
  width: 130px !important;
  height: 130px !important;
}
.sign-in-notify {
  position: absolute;
  width: 80px;
  height: 20px;
  background: #e9fbf3;
  border: 1px solid #a9efcf;
  border-radius: 4px;
}

span.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.plus-btn {
  height: 36px !important;
  background: #134989 !important;
  border: 1px solid #134989 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  padding: 0 7px !important;
}

a.close-new-btn svg {
  width: 38px !important;
  height: 38px !important;
}

.plus-close-btn {
  height: 38px !important;
  width: 38px !important;
  background: #134989 !important;
  border: 1px solid #134989 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
}

.multi-branch-list .css-13cymwt-control {
  height: 100% !important;
}

.css-1p3m7a8-multiValue {
  background-color: #f0f0f0 !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 4px !important;
}

.btn-cleaner-count {
  background: #f7f7f7 !important;
  border-radius: 5px !important;
  border: 1px solid #efefef !important;
  color: #134989 !important;
  padding: 2px 8px !important;
}

.check-left-space {
  margin-left: 10px;
}

#time {
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
  padding: 0.375rem 0.75rem !important;
  height: 26px !important;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.check-time .MuiFormControl-root {
  top: -11px !important;
}

.mr-8px {
  margin-right: 8px;
}

hr {
  border-top: 1px solid #cfcfcf !important;
}

.schedule-select-date {
  min-width: 250px;
  margin-right: 16px;
  margin-top: -8px;
}

.schedule-specific-date input {
  width: 250px !important;
  height: 36px !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
}

.form-check-input:checked {
  background-color: #134989 !important;
  border-color: #134989 !important;
}

.schedule-specific-date {
  margin-top: -8px;
}

.schedule-every-select-day {
  min-width: 250px;
  margin-top: -8px;
}

.schedule-start-date {
  width: 200px;
  margin-top: -8px;
  margin-right: 16px !important;
}

.schedule-end-date {
  width: 200px;
  margin-top: -8px;
  margin-left: 1.5rem;
}

input#start-time,
input#end-time,
input#edit-start-time,
input#edit-end-time {
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
  padding: 0.375rem 0.75rem !important;
  height: 26px !important;
}

.schedule-start-time {
  margin-top: -8px;
  margin-right: 1.5rem;
}

.schedule-end-time {
  margin-top: -8px;
  margin-left: 1.5rem;
}

.popover {
  margin-left: "-10%" !important;
}

.check-time input {
  background: #ffffff !important;
  border-radius: 8px !important;
  height: 26px !important;
  font-size: 13px !important;
  padding: 0.375rem 0.75rem;
}

.edit-task-start-end {
  margin-top: -26px;
  margin-left: 10px;
}

.edit-task-time fieldset div:nth-last-child(1) {
  margin-top: 20px;
}

.edit-task-time fieldset div:nth-last-child(2) label {
  margin-left: 8px;
}

.cleaners-count {
  transform: translate(915px, 235px) !important;
}

.unsatisfied-option {
  background: #fee7f3;
  border: 1px solid #fb9dcf;
  border-radius: 8px;
  padding: 4px 12px;
}

.satisfied-option {
  background: #f6e9fb;
  border: 1px solid #daa8f0;
  border-radius: 8px;
  padding: 4px 12px;
}

.attach-btn {
  height: 40px !important;
  width: 166px !important;
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
}

.comment-field {
  height: 88px !important;
  width: 528px !important;
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
}

.daily-task-update-date p {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 10px !important;
  color: #9d9d9d !important;
}

.select-status {
  width: 166 !important;
  height: 40px !important;
  background: #ffffff !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
}

td,
th {
  border-color: #f0f0f0 !important;
}

.w-170px {
  width: 170px !important;
}

.fs-12px {
  font-size: 13px !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bold-600 {
  font-weight: 600 !important;
}

#date-alert {
  color: red !important;
}

#schedule-end-date-alert {
  color: red !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #134989 !important;
  border-color: #134989 !important;
  color: #ffffff !important;
}

.pagination > li > a {
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  padding: 10px 12px;
  outline: none;
  cursor: pointer;
  color: #000000;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.pagination li a {
  text-decoration: none !important;
  color: #000000 !important;
}

.tox.tox-tinymce {
  width: 100% !important;
  border: 1px solid #f0f0f0 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.signin-badge {
  background: #e9fbf3;
  border: 1px solid #a9efcf;
  border-radius: 5px;
  width: 100px;
  text-align: center;
}

.notification-badge {
  border-radius: 5px;
  width: 100px;
  text-align: center;
}

.view-task-comment-badge {
  border-radius: 5px !important;
  padding: 0px 7px !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 22px !important;
  width: 120px !important;
  margin-bottom: 0 !important;
  color: #000000 !important;
}

table .view-task-comment-badge,
.view-task-comment-badge {
  width: auto !important;
  display: inline-block;
  min-width: 62px;
}

.signout-badge {
  background: #fbe9eb;
  border: 1px solid #f0a8af;
  border-radius: 5px;
  width: 100px;
  text-align: center;
}

.statisfied-badge {
  background: #f6e9fb;
  border: 1px solid #daa8f0;
}

.unstatisfied-badge {
  background: #fee7f3;
  border: 1px solid #fb9dcf;
}

.pl-pr-25px {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.notification-tab li button {
  font-weight: 600 !important;
  color: #000000 !important;
}

.notification-tab .nav-tabs .nav-link.active,
.nav-tabs .nav-link.active {
  background-color: transparent !important;
  border-radius: 0 !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 2px solid #134989 !important;
}

.notification-tab .nav-tabs .nav-link:hover,
.nav-tabs .nav-link:hover {
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
}
.nav-tabs button {
  font-weight: 600 !important;
  color: #000000 !important;
}
.task-history .download {
  right: 0 !important;
}

.modal-content {
  background-color: #f9f9f9 !important;
}

.select-status {
  border: 1px solid #f0f0f0 !important;
  padding: 0.375rem 1.25rem 0.375rem 0.75rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  height: 40px !important;
  color: #000000 !important;
}

.modal-body {
  padding: 2.25rem !important;
}

.modal-header {
  padding: 1rem 2.25rem !important;
}

.task-view .scroll-view {
  height: 450px !important;
  overflow-y: auto !important;
}

.view-cleaner-count {
  background: #f0f0f0;
  border-radius: 4px;
  padding: 2px 8px;
}

.mr-20px {
  margin-right: 20px;
}

.error-alert {
  background: #fbe9eb !important;
  border: 1px solid #f0a8af !important;
  border-radius: 8px !important;
  width: 437px !important;
  height: 68px !important;
  z-index: 9999;
  position: fixed;
  top: 40px;
  right: 15px;
}

.pointer {
  cursor: pointer;
}

.success-alerts {
  background: #e9fbf3 !important;
  border: 1px solid #a9efcf !important;
  border-radius: 8px !important;
  width: 437px !important;
  height: 68px !important;
  z-index: 9999;
  position: fixed;
  top: 40px;
  right: 15px;
}

.yellow-alert {
  background: #fff9e5 !important;
  border: 1px solid #ffe599 !important;
  border-radius: 8px !important;
  width: 437px !important;
  height: 68px !important;
  z-index: 9999;
  position: fixed;
  top: 40px;
  right: 15px;
}

.pagination .previous a {
  border-radius: 5px 0 0 5px !important;
}

.pagination .next-page a {
  border-radius: 0 5px 5px 0 !important;
}

.edit-comments.modal {
  z-index: 1300 !important;
}
.delete-modal.modal {
  z-index: 1300 !important;
}

.task-history ~ .modal-backdrop {
  z-index: 1200 !important;
}

/* // react tags input
 */

.react-tagsinput {
  border: 1px solid #f0f0f0;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  border-radius: 8px;
  font-size: 13px;
  min-height: 40px;
}

.react-tagsinput-tag {
  padding: 5px;
  background: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  margin-right: 5px;
}

.react-tagsinput-remove {
  text-decoration: none;
  padding-left: 15px;
}

.react-tagsinput-remove:after {
  content: "";
  background: url("../images/x.png") no-repeat 0px 0px;
  width: 15px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.react-tagsinput-input {
  border: none;
}

.react-tagsinput-input:focus-visible {
  outline: none;
}

.css-1qs2btb-control {
  border-radius: 8px !important;
  min-height: 36px !important;
}

.table-btn {
  --bs-btn-padding-x: 0rem !important;
  --bs-btn-padding-y: 0rem !important;
}

.action-btn {
  background: #ffffff !important;
  border-color: #ffffff !important;
  padding: 0 !important;
}
.progress-bar {
  background-color: #134989 !important;
}
.comments-details .img-card .download {
  right: 0 !important;
}
.cus-progress .modal-content {
  background-color: transparent !important;
}
.react-confirm-alert-overlay {
  z-index: 999999999 !important;
  background-color: transparent !important;
}
.react-confirm-alert-body {
  width: auto !important;
}
.react-confirm-alert-body h1 {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 21px !important;
  line-height: 25px !important;
  color: #ffffff !important;
  margin-bottom: 15px !important;
  text-align: left !important;
}
.react-confirm-alert-button-group button[label="Yes"] {
  height: 40px !important;
  width: 85px !important;
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  float: left !important;
  color: #000000 !important;
}
.react-confirm-alert-button-group button[label="No"] {
  width: 85px !important;
  height: 40px !important;
  border: 1px solid #ffffff !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  background: transparent !important;
  color: #ffffff !important;
}
.react-confirm-alert-body {
  background: #134989 !important;
  color: #ffffff !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 24px !important;
}
/* #react-confirm-alert-firm-svg {
  height: 0px !important;
}
#react-confirm-alert {
  z-index: 9999999999 !important;
  position: sticky !important;
  width: auto !important;
  text-align: center !important;
  padding: 0 37% !important;
  margin-top: 15% !important;
}

.react-confirm-alert-overlay {
  border-radius: 10px !important;
  background: #134989 !important;
  padding: 30px !important;
  height: 200px !important;
}
.react-confirm-alert-body h1{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 21px !important;
    line-height: 25px !important;
    color: #fff !important;
    margin-bottom: 15px !important;
    text-align: left !important;
}
.react-confirm-alert-body{
    color: #ffffff !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 24px !important;
}

.react-confirm-alert-button-group button[label='Yes'] {
  height: 40px !important;
  width: 124px !important;
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  float: left !important;
  color: #000000 !important;
}
.react-confirm-alert-button-group {
    margin-top: 25px;
}
.react-confirm-alert-button-group button[label='No'] {
  width: 124px !important;
  height: 40px !important;
  border: 1px solid #ffffff !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  background: transparent !important;
  color: #ffffff !important;
} */

.attachment-preview-box iframe {
  min-height: 500px !important;
}

fieldset {
  border-color: #f0f0f0 !important;
}
.MuiInputBase-formControl {
  border-radius: 8px !important;
  height: 36px !important;
  background: #fff !important;
}

.MuiClock-root button {
  position: absolute !important;
  padding: 10px !important;
  border-radius: 50% !important;
}

.css-kjd0cg-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton {
  background-color: #42a5f5 !important;
  color: #fff !important;
}

.css-1wahiw3-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton {
  background-color: #42a5f5 !important;
  color: #fff !important;
}

.password-input {
  padding-right: 40px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.z-index-9999 {
  z-index: 9999 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-4 {
  margin-left: 4px !important;
}
.ml-8 {
  margin-left: 8px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-5 {
  padding-left: 5px !important;
}
.text-grey {
  color: #7d7d7d !important;
}
.task-status-btn {
  background: none !important;
  border: none !important;
  padding: 0 !important;
}
.task-details-modal ~ .modal-backdrop {
  z-index: 1070 !important;
}
.delete-modal {
  z-index: 1080 !important;
}
.signout-svg-icon-btn {
  padding: 0 !important;
  background: none !important;
  border: none !important;
}
.signout-svg-icon-btn svg {
  width: 60px !important;
  height: 60px !important;
}
.scan-svg-icon-btn {
  padding: 0 !important;
  background: none !important;
  border: none !important;
}
.scan-svg-icon-btn svg {
  width: 60px !important;
  height: 60px !important;
}
.scan-modal-box section {
  width: 400px;
  height: 400px;
  margin: 0 auto !important;
}
.mh-100px {
  min-height: 200px !important;
}
.btn.btn-primary.modal-back-btn {
  font-size: 13px !important;
  line-height: 20px !important;
  border: 1px solid #7d7d7d !important;
  background: transparent !important;
  color: #7d7d7d !important;
  border-radius: 100px !important;
  padding: 0.375rem 0.75rem !important;
}
.btn.btn-primary.modal-back-btn span {
  color: #7d7d7d !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
  line-height: 20px !important;
}
.update-btn-svg,
.close-btn-svg {
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}
.w-330px {
  width: 330px !important;
}
.pr-5px {
  padding-right: 5px !important;
}
.w-200px {
  width: 200px;
}
.btn-border {
  background: transparent !important;
  border: 1px solid #134989 !important;
  color: #134989 !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  /* height: 36px !important; */
  line-height: 20px !important;
}
a {
  color: #01aee8 !important;
}
.cleaner-status-scroll {
  min-height: 341px !important;
  height: 341px !important;
  overflow-y: auto;
}
.filter-w-180px {
  width: 180px !important;
}
.flex-mr-16px {
  margin-right: 16px;
}
.btn-chat {
  background: #134989 !important;
  border-color: #134989 !important;
  font-size: 12px !important;
}
.data-text-style {
  padding: 0.375rem 0.75rem;
  border: 1px solid #fff;
  border-radius: 8px;
  font-size: 13px !important;
  margin-bottom: 0 !important;
}
.select-row-bg-green .data-text-style {
  border: 1px solid #e9ffe9 !important;
}

@media (max-width: 576px) {
  .col-md-6.col-49per {
    width: 100% !important;
  }
  .res-mb-3 {
    margin-bottom: 1rem !important;
  }

  .res-mt-3 {
    margin-top: 1rem !important;
  }

  .cus-w19-per {
    width: 100% !important;
  }
  .content-wrapper {
    margin-top: 100px !important;
  }
  .scan-modal-box section {
    width: 100% !important;
    height: auto !important;
  }
  .filter-w-180px {
    width: 100% !important;
  }
  .filter-button {
    width: 100% !important;
  }
  .mob-res-w-100 {
    width: 100% !important;
    margin-top: 16px;
  }
  .flex-mr-16px {
    margin-right: 0 !important;
  }
  .position-fixed.pl-300px {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .res-mtb-10px {
    margin: 10px 0;
  }
  .res-mb-10px {
    margin-bottom: 10px !important;
  }
  .topnav {
    height: auto !important;
  }
  .fix-dash-right-card {
    position: unset !important;
    margin: 0 !important;
    height: auto !important;
  }
}

@media (max-width: 768px) {
  .responsive-w-100 {
    width: 100%;
  }

  .p-1-5-rem {
    padding: 1.5rem !important;
  }

  .col-md-5.res-d-none {
    display: none !important;
  }

  .res-justify-con-center {
    justify-content: center;
  }

  .pos-back {
    left: 30px;
  }

  .d-none.res-d-block {
    display: block !important;
  }
  .topnav {
    width: 100% !important;
  }
  .main-panel {
    width: 100% !important;
  }
  .sidebar-offcanvas {
    right: 0 !important;
    left: unset !important;
  }
  .sidebar-offcanvas.show {
    display: none !important;
  }
  .sidebar-offcanvas.hide {
    display: block !important;
  }
  .chat-input {
    width: 100% !important;
  }
  .position-fixed.pl-300px {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .fix-dash-right-card {
    position: unset !important;
    margin: 0 !important;
    height: auto !important;
  }
}

@media screen and (max-width: 3280px) {
  .sidebar-offcanvas.active {
    right: 0;
  }

  .sidebar-offcanvas {
    position: fixed;
    max-height: 100vh;
    top: 0;
    bottom: 0;
    overflow: auto;
    left: 0;
    z-index: 999;
    -webkit-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
  }

  .sidebar-offcanvas.show {
    display: block;
  }

  .sidebar-offcanvas.hide {
    display: none;
  }

  .main-panel2 {
    margin-left: 0;
    width: 100%;
  }

  .topnavshow {
    width: 95% !important;
    border-bottom: 1px solid #e9e9e9 !important;
  }

  .lg-mt-3 {
    margin-top: 1rem !important;
  }
  .cus-w19-per {
    width: 100% !important;
  }
  .cus-w19-per.res-mb-3 {
    margin-bottom: 1rem !important;
  }
  .task-view .task-history .scroll-view {
    height: unset !important;
    overflow-y: unset !important;
  }
  .d-none.res-d-block {
    display: block !important;
  }

  .pr-5px {
    padding-right: 12px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main-panel2 .content-wrapper {
  margin: 0 !important;
}

.chat-relative {
  position: relative;
}

.chat-absolute {
  position: absolute;
  top: 3px;
  right: 50px;
  color: white !important;
}
.chat-input {
  position: fixed;
  bottom: 0;
  padding: 25px;
  background: #ffffff;
  right: 0;
  width: calc(799px);
}
.chat-list-wrap {
  padding-bottom: 90px;
}
.my-chat {
  background: #e9f1fc !important;
  border: 1px solid #d2e4f9 !important;
}
.w-100px {
  width: 100px !important;
}
.chat-btn {
  color: #134989 !important;
  border-color: #134989 !important;
  background: transparent !important;
  border-radius: 8px !important;
  font-size: 13px !important;
  height: 32px !important;
  line-height: 20px !important;
  width: 100px;
  padding: 0 7px !important;
}

.dashboard-table td {
  font-size: 12px !important;
}

.fw-700 {
  font-weight: 700;
}
.p-37px {
  padding: 37px !important;
}
.pdf-card {
  border: 1px solid #f0f0f0;
  box-shadow: 2px 2px 5px #d8d8d8;
  margin-bottom: 16px;
}
.br-top {
  border-top: 1px solid #e9e9e9 !important;
}
.btn-prev-next {
  background: #134989 !important;
  border: 1px solid #134989 !important;
  color: #ffffff !important;
  font-weight: 400 !important;
}
.position-fixed.pl-300px {
  padding-left: 300px;
  padding-right: 40px;
  box-shadow: 0px 0px 5px #d8d8d8 !important;
}
.mb-7rem {
  margin-bottom: 7rem !important;
}
.pb-37px {
  padding-bottom: 37px !important;
}

.signature-pad canvas {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  overflow: hidden !important;
  /* width: 100% !important; */
}

.contract-document-download svg {
  width: 30px;
  height: 30px;
}

.img-card .contract-document-download {
  right: 10px;
}

.select-row-bg-green {
  background-color: #e9ffe9 !important;
}

.pr-3 {
  padding-right: 1rem !important;
}
.text-right {
  text-align: right !important;
}
.table-data-text {
  cursor: pointer !important;
}
.dash-count-box {
  padding: 16px 20px !important;
}
.complaint-count p {
  background: #134989;
  color: #ffffff !important;
  border-radius: 100px;
  padding: 0px 7px;
  font-size: 12px !important;
}
.complaint-count {
  position: absolute;
  top: -10px;
  right: -12px;
  z-index: 9999;
}
.today-cleaner-right {
  margin-top: 76px;
  padding-left: 10px;
  padding-right: 10px;
}
.cleaner-singned td {
  border: none !important;
  padding: 2px !important;
}
.cleaner-singned .branch {
  color: #7d7d7d !important;
}
.cleaner-singned {
  border-bottom: 1px solid #f0f0f0;
  padding-top: 6px !important;
  padding-bottom: 8px !important;
}
.fix-dash-right-card {
  position: fixed !important;
  height: calc(100vh - 120px) !important;
  margin-right: 2.25rem !important;
  overflow-y: auto;
} 
.dash-noti-cont-scroll {
  overflow-y: auto;
  max-height: 370px;
}
.dash-noti-cont-scroll .card-body {
  padding: 8px 14px !important;
}
.noti-view-all-btn {
  margin: 2px 0 10px 0 !important;
}
.chat-list-wrap .card-body {
  padding: 6px 12px !important;
}
.chat-complaint-des {
  border-bottom: 1px solid #f0f0f0;
}
.col-49per {
  width: 49% !important;
}
.chat-attachment {
  background-color: #ffffff !important;
  border-radius: 8px !important;
  border: 1px solid #f0f0f0 !important;
}
.chat-complaint .branch-text {
  font-size: 13px !important;
  font-weight: 400 !important;
}
.chat-complaint .offc-title {
  font-size: 20px !important;
}
.chat-upload-img img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 8px;
  height: auto;
  object-fit: cover;
  border: 3px solid #dddddd !important;
}
select.form-select.chat-status {
  border: none !important;
  background-color: #f9f9f9;
  padding: 0 !important;
  height: auto !important;
}
.chat-attachment {
  height: 40px;
}
.img-box {
  position: relative;
  width: 50px !important;
  height: 50px !important;
}

.img-box span {
  position: absolute;
  top: 13px;
}

.img-box .delete {
  right: 0;
  cursor: pointer;
}
.chat-input .img-box img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
  border: 3px solid #dddddd !important;
}

.send-icon-btn {
  padding: 6px 12px !important;
  width: auto !important;
}
.reload-icon-btn {
  background-color: transparent !important;
  border: none !important;
}
.data-ins-plus.close-new-btn svg {
  height: 36px !important;
  width: 36px !important;
}
.data-ins-plus.plus-btn svg {
  height: 22px !important;
  width: 22px !important;
}
.form-control.br-dark {
  border: 1px solid #dddddd !important;
}
.view-inspec td,
.view-inspec th {
  border-bottom-width: 1px;
}
.view-inspec .form-switch .form-check-input {
  margin-left: 0 !important;
}
.approve-badge {
  background: #e9f1fc;
  border: 1px solid #a5c9f3;
  border-radius: 5px;
  padding: 0px 7px;
  width: 80px;
  text-align: center;
  font-size: 12px !important;
  line-height: 22px !important;
  float: right;
}
.w-95per {
  width: 95% !important;
}
.w-97_4per {
  width: 97.4% !important;
}
.cleaner-act-accordion .accordion-item {
  margin-bottom: 16px !important;
  border: 1px solid #f0f0f0 !important;
  border-radius: 8px !important;
}
.cleaner-act-accordion .accordion {
  border-color: #f0f0f0 !important;
}
.cleaner-act-accordion .accordion-button {
  background: none !important;
  border-radius: none !important;
}
.accordion-button:focus {
  border-color: transparent !important;
}
.cus-accr-row {
  padding: 16px 20px;
  border-bottom: 1px solid #f1f1f1;
}
table.view-audit td {
  vertical-align: top !important;
}
.modal-title {
  text-transform: capitalize !important;
}
.sm-badge.view-task-comment-badge {
  font-size: 11px !important;
  height: 20px !important;
  line-height: 18px !important;
}
.cont-export-pdf {
  padding: 2px 7px !important;
  border-radius: 5px !important;
}
.add-audit {
  width: 134px !important;
}
.add-schedule-btn {
  width: 134px !important;
}
.add-daily-task-btn {
  width: 134px !important;
}
@media (max-width: 768px) {
  .add-daily-task-btn {
    width: 100% !important;
  }
  .fix-dash-right-card {
    position: unset !important;
    margin: 0 !important;
    height: auto !important;
  }
}
@media (max-width: 576px) {
  .add-quoted-task {
    width: 100% !important;
    margin-top: 16px;
  }
  .fix-dash-right-card {
    position: unset !important;
    margin: 0 !important;
    height: auto !important;
  }
}
/* .add-quoted-task {
  width: 134px !important;
} */
/* .add-complaint-btn {
  width: 134px !important;
} */
.w-30per {
  width: 30% !important;
}
.complaint-status .css-13cymwt-control {
  background-color: transparent !important;
  border-color: transparent !important;
}

.flex-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.tox .tox-editor-header {
  z-index: 0 !important;
}
.certificate {
  position: relative;
  width: auto !important;
  height: auto !important;
}

.InvoiceReport {
  display: flex;
  flex-direction: row;
}

.InvoiceReport p {
  margin-left: auto;
}

/* .invoice_report {
  display: flex;
  margin: auto;
  position: absolute;
  right: 0%;
  flex-direction: row; 
  justify-content: space-between;
  align-items: flex-end;
  align-self: flex-end;
} */

.created-option {
  background: #fbe9eb;
  border: 1px solid #f0a8af;
  border-radius: 8px;
  padding: 4px 12px;
}

.inprogress-option {
  background: #fff9e6;
  border: 1px solid #ffe699;
  border-radius: 8px;
  padding: 4px 12px;
}

.completed-option {
  background: #e9fbf3;
  border: 1px solid #a9efcf;
  border-radius: 8px;
  padding: 4px 12px;
}

.commented-option {
  background: #e6f0ff;
  border: 1px solid #9ac2fe;
  border-radius: 8px;
  padding: 4px 12px;
}
.incompleted-option {
  background: #fff2e6;
  border: 1px solid #ffcb99;
  border-radius: 8px;
  padding: 4px 12px;
}
.resolved-option {
  background: #f6e9fb;
  border: 1px solid #daa8f0;
  border-radius: 8px;
  padding: 4px 12px;
}

.followup-option {
  background: #fee7f3;
  border: 1px solid #fb9dcf;
  border-radius: 8px;
  padding: 4px 12px;
}

.created-badge {
  background: #fbe9eb;
  border: 1px solid #f0a8af;
}

.inprogress-badge {
  background: #fff9e6;
  border: 1px solid #ffe699;
}
.completed-badge {
  background: #e9fbf3 !important;
  border: 1px solid #a9efcf !important;
}
.commented-badge {
  background: #e6f0ff;
  border: 1px solid #9ac2fe;
}

.incompleted-badge {
  background: #fff2e6;
  border: 1px solid #ffcb99;
}

.resolved-badge {
  background: #f6e9fb;
  border: 1px solid #daa8f0;
}

.followup-badge {
  background: #fee7f3;
  border: 1px solid #fb9dcf;
}

.satisfied-badge {
  background: #e9fbf3;
  border: 1px solid #a9efcf;
}
.unsatisfied-badge {
  background: #fbe9eb;
  border: 1px solid #f0a8af;
}

.invoice-export-modal .container {
  margin-left: 150px !important;
  margin-right: 150px !important;
  border-bottom: 1px solid black !important;
}

.invoice-export-modal .top-section {
  margin-top: 20px !important;
  display: flex !important;
  border-bottom: 1px solid black !important;
}

.invoice-export-modal .top-section-address {
  display: flex !important;
  align-items: end !important;
  justify-content: end !important;
  color: #134989 !important;
  font-weight: 500 !important;
}

.invoice-export-modal .row {
  display: flex !important;
}

.invoice-export-modal .column {
  flex: 1 !important;
}

.invoice-export-modal .column1 {
  flex: 1 !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: 600 !important;
}

.invoice-export-modal .column2 {
  margin-top: -25px !important;
  flex: 1 !important;
  display: flex !important;
  justify-content: center !important;
  font-weight: 400 !important;
}

.invoice-export-modal .head {
  text-align: left !important;
  background-color: rgb(255, 174, 0) !important;
}

.invoice-export-modal td {
  padding-top: 16px !important;
  font-weight: 500 !important;
  font-family: poppins !important;
}

.invoice-export-modal th {
  padding: 5px !important;
}

.invoice-export-modal .para {
  margin: 8px !important;
  font-weight: 600 !important;
}

.invoice-export-modal .total {
  display: flex !important;
  float: right !important;
}

.invoice-export-modal .column3 {
  text-align: end !important;
}

.invoice-export-modal table {
  border-spacing: 0px !important;
}

.invoice-export-modal .total {
  margin-top: 300px !important;
}

.invoice-export-modal .column4 {
  text-align: end !important;
  margin-top: 20px !important;
}

.invoice-export-modal .box {
  float: left !important;
  border: 1px solid black !important;
  padding: 4px !important;
  margin-top: 20px !important;
  /* width: 65% !important; */
}

.invoice-export-modal h2 {
  padding: 0px !important;
  font-family: poppins !important;
}

.invoice-export-modal .box1 {
  clear: both !important;
  float: left !important;
  border: 1px solid black !important;
  padding: 4px !important;
  width: 65% !important;
  margin-bottom: 20px !important;
  margin-top: 2px !important;
}

.invoice-export-modal p {
  font-family: poppins !important;
}

.invoice-export-modal table {
  width: "100%" !important;
}
.invoice-export-modal-modal {
  background: #0000006b !important;
}

.bold-text {
  font-weight: bold !important;
}

.total_payment {
  width: 50%;
  background-color: #01b46f;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}

.invoice_input {
  font-size: 12px !important;
}
.invoice_input_export {
  font-size: 12px !important;
}
/* The above code is defining CSS styles for different elements. */
.canvas-container {
  height: 460px !important;
  width: 480px !important;
}
.lower-canvas {
  height: 400px !important;
  width: 450px !important;
}
.upper-canvas {
  height: 400px !important;
  width: 450px !important;
}
#draw-button {
  height: 50px;
  border: 1px solid !important;
  margin-left: 4% !important;
}
.no-notificate {
  text-align: center;
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #7d7d7d !important;
}
.user-type {
  font-size: 12px;
  color: gray;
  text-transform: capitalize;
}
/* .text-container{
  width: 300px;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
} */
.total-time {
  text-align: center;
  padding-right: 3% !important;
}
.role-list {
  width: auto !important;
  margin-left: 16px;
}
.approved-task-button{
  color: #ffffff !important;
  background: #134989 !important;
  border-color: #134989 !important;
  width: auto;
  border-radius: 8px !important;
  font-size: 13px !important;
  height: 36px !important;
  line-height: 20px !important;
}
.MuiButton-root{
  text-transform: none !important;
}
.MuiButton-label {
color: #ffffff !important;
}
p.mb-0.profile-ep{
  overflow: auto;
}
.response{
  width: 100% !important;
}
.export-approved{
float: right;
margin-top: 16px;
}
@media (max-width: 576px) {
  .add-role-button{
    width: 100% !important;
    margin-top: 16px;
    /* float: right !important; */
  }
}
.add-role-button{
float: right;
}
@media (max-width: 576px) {
  .add-user-button{
    margin-top: 16px;
  }
  .add-client-contract{
    margin-top: 16px;
  }
  .margin-top-fix{
    margin-top: 16px;
  }
  .add-audit{
    width: 100% !important;
  }
  .add-schedule-btn{
    width: 100% !important;
  }
  .add-daily-task-btn{
    margin-top: -16px!important;
  }
}
/* .approved-btn{
  background-color: #01b46f !important;
  border: none !important;
} */

.approved-btn{
  background-color: rgb(255, 174, 0) !important;
  border: none !important;
}
.comment-delete-btn{
  height: 36px !important;
  width: 5.5625rem !important;
  background: #ffffff;
  border: 1px solid #000000 !important;
  /* color: #000000 !important; */
  border-radius: 8px !important;
  font-size: 13px !important;
}
.delete-audit-cancel-btn {
  color: #000000 !important;
}
.move-to-task-btn{
  background: #fbe9eb !important;
  border: 1px solid #f0a8af !important;
  color: #000000 !important;
  border-radius: 5px;
}
/* .move-to-task-text{
  padding: 3px;
} */
.move-to-recommende-task-btn{
  background: rgb(211, 248, 228) !important;
  border: 1px solid #01b46f !important;
  color: #000 !important;
  border-radius: 5px;
}
.move-to-recommended-task-text{
  padding: 3px;
}
.add-recommended-btn {
  margin-left: 65% !important;
  background: #134989 !important;
  padding: 3px !important;
}